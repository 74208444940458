import { CoachStatusEnum } from 'types/generated/client';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useViewer } from 'hooks/useViewer';
import CoachHome from 'screens/CoachHome';
import PlayerHome from 'screens/PlayerHome';

const AnonHomePage = () => {
  const { isAnonymousSession, isSessionLoading } = useViewer();
  const { user, loading, called } = useGetCurrentUser();

  if (isSessionLoading) {
    return <PlayerHome isServerPlayer={false} isServerAnonymous={true} />;
  }

  if (isAnonymousSession) {
    return <PlayerHome isServerPlayer={false} isServerAnonymous={true} />;
  }

  if (loading || !called) {
    return <PlayerHome isServerPlayer={false} isServerAnonymous={true} />;
  }

  if (user?.coachStatus === CoachStatusEnum.Active) {
    return <CoachHome />;
  }

  if (!!user) {
    return <PlayerHome isServerPlayer={true} isServerAnonymous={false} />;
  } else {
    return <PlayerHome isServerPlayer={false} isServerAnonymous={true} />;
  }
};

export default AnonHomePage;
